import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MdOutlinePhoneAndroid, MdElectricalServices } from 'react-icons/md'
import { BiWallet } from 'react-icons/bi'
import { RiCoupon2Line } from 'react-icons/ri'
import { LuGamepad2 } from 'react-icons/lu'

import { ProductCategory } from '@types'

import './styles.scss'
import { classNames } from '@utils'

interface Props {
  data?: ProductCategory
}

export const CategoryCard: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate()
  return (
    <div
      className={classNames(
        'category-card',
        !data ? 'skeleton' : ''
      )}
      onClick={() => navigate(`/category/${data?.id}`)}
    >
      {data ?
        <>
          {data?.name.toLowerCase().includes('wallet') || data?.name.toLowerCase().includes('money') ?
            <BiWallet size={30} />
            :
            data?.name.toLowerCase().includes('pulsa') ?
              <MdOutlinePhoneAndroid size={30} />
              :
              data?.name.toLowerCase().includes('pln') || data?.name.toLowerCase().includes('listrik') ?
                <MdElectricalServices size={30} />
                :
                data?.name.toLowerCase().includes('voucher') ?
                  <RiCoupon2Line size={30} />
                  :
                  data?.name.toLowerCase().includes('game') ?
                    <LuGamepad2 size={30} />
                    :
                    <img className="icon" src={data?.image} alt="category-icon" />
          }
        </>
        :
        <div className="icon-skeleton" />
      }
      <div className="name">{data?.name}&nbsp;</div>
    </div>
  )
}
