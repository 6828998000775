import React, { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { api, CLIENT_CODE, setToken } from '@utils'

export const App: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const login = useCallback(async (token: string) => {
    try {
      const res = await api.get(`/auth/member?token=${token}&client_code=${CLIENT_CODE}`)
      const { status_code, data } = res.data
      if (status_code === 200) {
        setToken(data.token)
        localStorage.setItem('token', data.token)
        setTimeout(() => navigate('/home'), 500)
      }
    } catch {
      navigate('/401')
    }
  }, [navigate])

  useEffect(() => {
    const token = searchParams.get('token')
    const storeToken = localStorage.getItem('token')
    if (token) {
      login(token)
    } else if (storeToken) {
      setToken(storeToken)
      setTimeout(() => navigate('/home', { replace: true }), 500)
    } else {
      navigate('/401')
    }
  }, [login, navigate, searchParams])

  return (
    <div></div>
  )
}
