import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IoArrowBack } from 'react-icons/io5'

import './styles.scss'

interface Props {
  title: string
  goBack?: boolean
  onBack?: () => void
}

export const Topbar: React.FC<Props> = ({ title, goBack, onBack }) => {
  const navigate = useNavigate()
  return (
    <div className="topbar">
      {(goBack || onBack) &&
        <IoArrowBack
          className="back"
          size={25}
          onClick={() => goBack ? navigate(-1) : onBack ? onBack() : null}
        />
      }
      <div
        className="title"
        style={{ paddingRight: goBack || onBack ? 25 : 0 }}
      >
        {title}
      </div>
    </div>
  )
}
