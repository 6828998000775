import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Product } from '@types'
import { classNames, separator } from '@utils'

import './styles.scss'

interface Props {
  data?: Product
}

export const Voucher: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate()
  return (
    <div
      className={classNames(
        'voucher',
        !data ? 'skeleton' : ''
      )}
      onClick={() => navigate('/product/detail', { state: { data } })}
    >
      <div
        className="image"
        style={{ backgroundImage: `url(${data?.image})` }}
      />
      <div className="detail">
        <div className="name">{data?.name}&nbsp;</div>
        <div className="point">{separator(Number(data?.required_point))} Points</div>
      </div>
    </div>
  )
}
