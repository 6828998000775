import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { IoCloseSharp } from 'react-icons/io5'
import { GrCircleAlert } from 'react-icons/gr'
import { TbTags } from 'react-icons/tb'
import { FaRegCheckCircle } from 'react-icons/fa'
import moment from 'moment'
import OtpInput from 'react-otp-input'

import { Container, Topbar } from '@components'

import { Product, ProductParams, Profile } from '@types'
import { api, classNames, separator, setToken } from '@utils'

import './styles.scss'

export const DetailProduct: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const data = location?.state?.data as Product

  const [profile, setProfile] = useState<Profile>()
  const [product, setProduct] = useState<Product[]>([])
  const [phone, setPhone] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [phoneLoading, setPhoneLoading] = useState<boolean>(false)
  const [emailLoading, setEmailLoading] = useState<boolean>(false)
  const [modal, setModal] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [validate, setValidate] = useState<string>('')
  const [otp, setOtp] = useState<boolean>(false)
  const [codePhone, setCodePhone] = useState<string>('')
  const [codeEmail, setCodeEmail] = useState<string>('')
  const [phoneOtp, setPhoneOtp] = useState<string>('')
  const [emailOtp, setEmailOtp] = useState<string>('')
  const [phoneRetryAfter, setPhoneRetryAfter] = useState<number>(0)
  const [emailRetryAfter, setEmailRetryAfter] = useState<number>(0)

  const electric = data?.code?.includes('MPHPLN')
  const pulsa =
    data?.code?.includes('MPHTELKOMSEL') ||
    data?.code?.includes('MPHINDOSAT') ||
    data?.code?.includes('MPXLD') ||
    data?.code?.includes('MPHAXIS') ||
    data?.code?.includes('MPHSMART') ||
    data?.code?.includes('MPHTHREE') ||
    data?.code?.includes('MPHBYU')
  const digital = data?.code?.includes('MP')

  const prefix = [
    { prefix: '0811,0812,0813,0821,0822,0823,0851,0852,0853', code: 'MPHTELKOMSEL', name: 'telkomsel', icon: require('@assets/icons/operator_telkomsel.png') },
    { prefix: '0814,0815,0816,0855,0856,0857,0858', code: 'MPHINDOSAT', name: 'indosat', icon: require('@assets/icons/operator_indosat.png') },
    { prefix: '0817,0818,0819,0859,0877,0878', code: 'MPXLD', name: 'xl', icon: require('@assets/icons/operator_xl.png') },
    { prefix: '0831,0832,0833,0837,0838', code: 'MPHAXIS', name: 'axis', icon: require('@assets/icons/operator_axis.png') },
    { prefix: '0881,0882,0883,0884,0885,0886,0887,0888,0889', code: 'MPHSMART', name: 'smartfren', icon: require('@assets/icons/operator_smartfren.png') },
    { prefix: '0895,0896,0897,0898,0899', code: 'MPHTHREE', name: 'three', icon: require('@assets/icons/operator_three.png') },
    { prefix: '085154,085155,085156,085157,085158', code: 'MPHBYU', name: 'byu', icon: require('@assets/icons/operator_byu.png') }
  ]

  let operator = phone && prefix.find(item => item.prefix.includes(phone.slice(0, 4)))
  if (operator && operator.name === 'telkomsel' && phone.length >= 6) {
    const byu = prefix.find(item => item.prefix.includes(phone.slice(0, 6)))
    if (byu) operator = byu
  }

  useEffect(() => {
    const storeToken = localStorage.getItem('token')
    if (storeToken) setToken(storeToken)
  }, [])

  const getProfile = useCallback(async () => {
    try {
      const res = await api.get('/api/member/v1/addon/sufi/profile')
      const { status_code, data } = res.data
      if (status_code === 200) {
        setProfile(data)
      }
    } catch (e: any) {
      setError(e?.response?.data?.message?.[0] || e?.response?.data?.message || e?.message || 'Network Error')
      if (e?.response?.data?.status_code === 401) navigate('/401')
    }
  }, [navigate])

  const getProduct = useCallback(async (params: ProductParams) => {
    try {
      const { page, limit, merchant_id } = params
      const res = await api.get(`/api/member/v1/products?limit=${limit}&page=${page}&merchant_id=${merchant_id}`)
      const { status_code, data } = res.data
      if (status_code === 200) setProduct(data)
    } catch (e: any) {
      setError(e?.response?.data?.message?.[0] || e?.response?.data?.message || e?.message || 'Network Error')
      if (e?.response?.data?.status_code === 401) navigate('/401')
    }
  }, [navigate])

  useEffect(() => {
    if (data?.merchant_id) {
      getProfile()
      getProduct({
        page: 1,
        limit: 100,
        merchant_id: Number(data.merchant_id)
      })
    } else {
      navigate('/home')
    }
  }, [data, getProfile, getProduct, navigate])

  useEffect(() => {
    if (error) setTimeout(() => setError(''), 2000)
  }, [error])

  const numbersLimit = 10
  let phoneNumbers: string[] = []
  const phoneStorage = localStorage.getItem('phone_numbers')
  if (phoneStorage) phoneNumbers = JSON.parse(phoneStorage)

  let tokenNumbers: string[] = []
  const tokenStorage = localStorage.getItem('token_numbers')
  if (tokenStorage) tokenNumbers = JSON.parse(tokenStorage)

  const openOTP = async () => {
    if ((pulsa || digital) && !electric && phone.length < 10) {
      setValidate('Phone number too short')
    } else if (pulsa && Boolean(operator && !data.code.includes(operator.code))) {
      setValidate('Incorrect provider phone number')
    } else if (!operator) {
      setValidate('Provider not found')
    } else {
      orderOTP('sms', data?.required_point || 0)
      orderOTP('email', data?.required_point || 0)
      setOtp(true)
    }
  }

  const redeemNow = async () => {
    try {
      setLoading(true)
      const body = {
        product_code: data.code,
        qty: 1,
        name: profile?.name,
        order_number: `ORDER${moment().format('YYYYMMDDHHmmss')}${Math.floor(Math.random() * 1000000000)}`,
        customer_number: phone || '081774859115',
        email: profile?.email,
        phone: phone || '081774859115',
        method: 'point',
        campaign_id: null,
        email_verification_code: codeEmail,
        sms_verification_code: codePhone
      }
      const res = await api.post('/api/member/v1/addon/sufi/order', body)
      const { status_code } = res.data
      console.log('STATUS_CODE', status_code)
      if (status_code === 200) {
        setCodeEmail('')
        setCodePhone('')
        if (res.data?.data?.status?.toLowerCase() === 'failed')
          navigate('/redeem-failed')
        else
          navigate('/redeem-success', { state: { sendEmail: electric }, replace: true })
      }
      setLoading(false)
      if (pulsa || digital) {
        const findPhone = phoneNumbers.findIndex(item => item === phone)
        if (findPhone === -1) {
          if (phoneNumbers.length >= numbersLimit) phoneNumbers.shift()
          phoneNumbers.push(phone)
          localStorage.setItem('phone_numbers', JSON.stringify(phoneNumbers))
        }
      }

      if (electric) {
        const findToken = tokenNumbers.findIndex(item => item === phone)
        if (findToken === -1) {
          if (tokenNumbers.length >= numbersLimit) tokenNumbers.shift()
          tokenNumbers.push(phone)
          localStorage.setItem('token_numbers', JSON.stringify(tokenNumbers))
        }
      }
    } catch (e: any) {
      setLoading(false)
      setError(e?.response?.data?.message?.[0] || e.response.data.message || e?.message || 'Network Error')
      if (e?.response?.data?.status_code === 401) navigate('/401')
    }
  }

  const orderOTP = async (type: 'email' | 'sms', point: number) => {
    try {
      if (type === 'email') setEmailLoading(true)
      if (type === 'sms') setPhoneLoading(true)
      const body = {
        type,
        point: point.toString()
      }
      const res = await api.post(`/api/member/v1/addon/sufi/order-otp`, body)
      const { status_code, data } = res.data
      if (status_code === 200) {
        if (type === 'email') {
          setEmailOtp(data.recipient)
          setEmailRetryAfter(300)
        }
        if (type === 'sms') {
          setPhoneOtp(data.recipient)
          setPhoneRetryAfter(300)
        }
        setOtp(true)
        if (type === 'email') setEmailLoading(false)
        if (type === 'sms') setPhoneLoading(false)
      }
    } catch (e: any) {
      if (type === 'email') setEmailLoading(false)
      if (type === 'sms') setPhoneLoading(false)
      setError(e?.response?.data?.message?.[0] || e?.response?.data?.message || e?.message || 'Network Error')
      if (e?.response?.data?.status_code === 401) navigate('/401')
    }
  }

  useEffect(() => {
    if (phoneRetryAfter === 0) setPhoneRetryAfter(0)
    if (!phoneRetryAfter) return

    const intervalId = setInterval(() => {
      setPhoneRetryAfter(phoneRetryAfter - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [phoneRetryAfter])

  useEffect(() => {
    if (emailRetryAfter === 0) setEmailRetryAfter(0)
    if (!emailRetryAfter) return

    const intervalId = setInterval(() => {
      setEmailRetryAfter(emailRetryAfter - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [emailRetryAfter])

  // useEffect(() => {
  //   if (code.length === 4 && otp) {
  //     setOtp(false)
  //     redeemNow()
  //   }
  // }, [code, otp])

  const minutesPhoneRetryAfter = Math.floor(phoneRetryAfter / 60)
  const secondsPhoneRetryAfter = phoneRetryAfter - minutesPhoneRetryAfter * 60

  const minutesEmailRetryAfter = Math.floor(emailRetryAfter / 60)
  const secondsEmailRetryAfter = emailRetryAfter - minutesEmailRetryAfter * 60

  return (
    <Container>
      <Topbar title={data?.name}
        onBack={() => navigate(`/category/${data?.product_category_id}`)}
      />
      <div className="detail-product">
        <div
          className="image"
          style={{
            backgroundImage: `url(${data?.image})`
          }}
        />
        {/* <div className="preview">
          <img
            src={data?.image} alt="voucher"
          />
        </div> */}
        <div className="detail">
          <div className="tags">
            <TbTags size={20} color="#ccc" />
            {data?.product_category.name !== data?.merchant.name &&
              <div
                className="tag"
                onClick={() => navigate(`/category/${data?.product_category_id}`, { state: { goBack: true } })}
              >
                {data?.product_category.name}
              </div>
            }
            <div
              className="tag"
              onClick={() => navigate(`/product/${data?.merchant_id}`)}
            >
              {data?.merchant.name}
            </div>
          </div>
          <div className="select-amount">Select Amount</div>
          <div className="amount">
            {product.sort((a, b) => a.nominal - b.nominal).map((item: Product, index: number) =>
              <div
                key={index}
                className={classNames(
                  'voucher',
                  item.required_point === data?.required_point ? 'active' : ''
                )}
                onClick={() => navigate('/product/detail', { state: { data: item } })}
              >
                <FaRegCheckCircle
                  className={classNames(
                    'check',
                    item.required_point === data?.required_point ? 'show' : 'hide'
                  )}
                  size={20}
                  color="#375AA8"
                />
                <div className="name">{item.name}</div>
                <div className="point">{separator(Number(item.required_point))} Points</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="bottom-action">
        <div
          className={classNames(
            'insufficient',
            (Number(profile?.point_redeem)) < data?.required_point ? 'show' : 'hide'
          )}
        >
          <GrCircleAlert size={20} style={{ marginRight: 5 }} color="#fff" />
          Insufficient Point, find out How to Earn More Points
        </div>
        <div className="content">
          <div className="point">
            <div className="product-point">{separator(Number(data?.required_point))} Points</div>
            <div className="my-point">
              My Points:&nbsp;
              <span
                style={{
                  color: profile?.point_redeem && (profile?.point_redeem < data?.required_point) ? '#b1161c' : 'unset'
                }}
              >
                {separator(Number(profile?.point_redeem || 0))}
              </span>
            </div>
          </div>
          <button
            className="button"
            onClick={() => {
              if (electric || pulsa || digital) {
                setModal(true)
              } else {
                redeemNow()
              }
            }}
            disabled={loading || (profile?.point_redeem || 0) < data?.required_point}
          >
            Redeem Now
          </button>
        </div>
      </div>
      <div
        className={classNames(
          'overflow',
          modal ? 'open' : 'close'
        )}
        onClick={() => setModal(false)}
      />
      <div
        className={classNames(
          'modal',
          modal ? 'open' : 'close'
        )}
      >
        {otp ?
          <div className="otp-box">
            {phoneLoading || emailLoading ?
              <img src={require('@assets/images/loader.svg').default} alt="loader" />
              :
              <>
                <div className="title">Enter Verification Code</div>
                <div className="info">A code has been sent to <strong>{emailOtp}</strong>.<br />Please check on your email.</div>
                {emailRetryAfter > 0 ?
                  <div className="resend">Request a new code in {minutesEmailRetryAfter < 10 ? `0${minutesEmailRetryAfter}` : minutesEmailRetryAfter}:{secondsEmailRetryAfter < 10 ? `0${secondsEmailRetryAfter}` : secondsEmailRetryAfter}</div>
                  :
                  <div className="resend" onClick={() => orderOTP('email', data?.required_point || 0)}>Resend</div>
                }
                <OtpInput
                  containerStyle="otp-container"
                  inputStyle="otp-input"
                  value={codeEmail}
                  onChange={setCodeEmail}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                />
                <div className="info">A code has been sent to <strong>{phoneOtp.slice(0, -4)}****</strong>.<br />Please check on your sms number.</div>
                {phoneRetryAfter > 0 ?
                  <div className="resend">Request a new code in {minutesPhoneRetryAfter < 10 ? `0${minutesPhoneRetryAfter}` : minutesPhoneRetryAfter}:{secondsPhoneRetryAfter < 10 ? `0${secondsPhoneRetryAfter}` : secondsPhoneRetryAfter}</div>
                  :
                  <div className="resend" onClick={() => orderOTP('sms', data?.required_point || 0)}>Resend</div>
                }
                <OtpInput
                  containerStyle="otp-container"
                  inputStyle="otp-input"
                  value={codePhone}
                  onChange={setCodePhone}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                />
                <button
                  className="button"
                  onClick={() => {
                    redeemNow()
                  }}
                  disabled={loading || !codeEmail || !codePhone}
                >
                  Submit
                </button>
              </>
            }
          </div>
          :
          <div>
            <div className="close-button">
              <IoCloseSharp
                size={30}
                onClick={() => setModal(false)}
              />
            </div>
            <div className="form">
              <div className="title">Input {electric ? 'Meter Number' : 'Phone Number'}</div>
              <div className="input">
                <input
                  type="tel"
                  placeholder={electric ? 'Meter Number' : 'Enter your phone'}
                  value={phone}
                  onChange={e => {
                    setPhone(e.target.value.replace(/[^0-9]/g, ''))
                    setValidate('')
                  }}
                  maxLength={electric ? 12 : 14}
                />
                {pulsa && phone.length >= 4 && operator && <img className="icon" src={operator.icon} alt="operator" />}
              </div>
              {validate &&
                <div className="validate">{validate}</div>
              }
              <div className="number-storage">
                {(pulsa || digital) && !electric &&
                  phoneNumbers.reverse().map((number, index) => {
                    const operator = prefix.find(item => item.prefix.includes(number.slice(0, 4)))
                    const active = (digital && !pulsa) || (operator && data.code.includes(operator.code))
                    return (
                      <div
                        key={index}
                        className={
                          classNames(
                            'number',
                            active ? 'active' : 'disabled'
                          )}
                        onClick={() => {
                          if (active)
                            setPhone(number)
                        }}
                      >
                        {number}
                      </div>
                    )
                  })}
                {electric &&
                  tokenNumbers.reverse().map((number, index) =>
                    <div
                      key={index}
                      className="number"
                      onClick={() => setPhone(number)}
                    >
                      {number}
                    </div>
                  )}
              </div>
              <div className="name">{data?.name}</div>
            </div>
            <div className="bottom-action">
              <div className="content">
                <div className="point">
                  <div className="product-point">{separator(Number(data?.required_point || 0))} Points</div>
                  <div className="my-point">
                    My Points:&nbsp;
                    <span
                      style={{
                        color: profile?.point_redeem && (profile?.point_redeem < data?.required_point) ? '#b1161c' : 'unset'
                      }}
                    >
                      {separator(Number(profile?.point_redeem || 0))}
                    </span>
                  </div>
                </div>
                {loading ?
                  <img src={require('@assets/images/loader.svg').default} alt="loader" />
                  :
                  <button
                    className="button"
                    onClick={() => {
                      // redeemNow()
                      openOTP()
                    }}
                    disabled={!phone || loading || (profile?.point_redeem || 0) < data?.required_point}
                  >
                    Redeem Now
                  </button>
                }
              </div>
            </div>
          </div>
        }
      </div>
      {error && <div className="error">{error}</div>}
    </Container>
  )
}
