import { Container } from '@components'
import React from 'react'

import './styles.scss'

export const Unauthorized: React.FC = () => {
  return (
    <Container>
      <div className="unauthorized">
        <div className="error-code">401</div>
        <div className="label">Unauthorized</div>
      </div>
    </Container>
  )
}
