import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { api, classNames, setToken } from '@utils'
import { Product, ProductMerchant, ProductParams } from '@types'
import { Container, Topbar, Voucher } from '@components'

import './styles.scss'

export const MerchantProduct: React.FC = () => {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const goBack = location?.state?.goBack

  const [title, setTitle] = useState<string>('')
  const [merchant, setMerchant] = useState<ProductMerchant[]>([])
  const [product, setProduct] = useState<Product[]>([])
  const [error, setError] = useState<string>('')

  const merchant_id = params.merchant_id
  const category_id = merchant.find(item => item.merchant_id === Number(merchant_id))?.product_category_id

  useEffect(() => {
    const storeToken = localStorage.getItem('token')
    if (storeToken) setToken(storeToken)
  }, [])

  useEffect(() => {
    if (merchant_id) {
      getProductMerchant(Number(merchant_id))
      getProduct({
        page: 1,
        limit: 100,
        merchant_id: Number(merchant_id)
      })
    }
    // eslint-disable-next-line
  }, [merchant_id])

  const getProductMerchant = async (id: number) => {
    try {
      const limit = 100
      const page = 1
      const res = await api.get(`/api/member/v1/product-merchants?limit=${limit}&page=${page}`)
      const { status_code, data } = res.data
      if (status_code === 200) {
        setMerchant(data)
        setTitle(data?.find((item: ProductMerchant) => item.merchant_id === id)?.merchant?.name || '')
      }
    } catch (e: any) {
      setError(e?.response?.data?.message?.[0] || e?.response?.data?.message || e?.message || 'Network Error')
      if (e?.response?.data?.status_code === 401) navigate('/401')
    }
  }

  const getProduct = async (params: ProductParams) => {
    try {
      const { page, limit, merchant_id } = params
      const res = await api.get(`/api/member/v1/products?limit=${limit}&page=${page}&merchant_id=${merchant_id}`)
      const { status_code, data } = res.data
      if (status_code === 200) setProduct(data)
    } catch (e: any) {
      setError(e?.response?.data?.message?.[0] || e?.response?.data?.message || e?.message || 'Network Error')
      if (e?.response?.data?.status_code === 401) navigate('/401')
    }
  }

  return (
    <Container>
      <Topbar
        title={title}
        onBack={() => navigate(`/category/${category_id}`)}
        goBack={goBack}
      />
      <div className="voucher-tags">
        <div className="tags">
          {merchant.filter(item => item.product_category_id === category_id).map((item, index) =>
            <div
              key={index}
              className={classNames(
                'tag',
                item.merchant_id === Number(merchant_id) ? 'active' : ''
              )}
              onClick={() => {
                setProduct([])
                navigate(`/product/${item.merchant_id}`, { state: { goBack } })
              }}
            >
              {item.merchant.name}
            </div>
          )}
        </div>
      </div>
      <div className="product">
        <div className="content">
          {product.length === 0 ?
            <>
              <Voucher />
              <Voucher />
            </>
            :
            product.sort((a, b) => a.nominal - b.nominal).map((item: Product, index: number) =>
              <Voucher
                key={index}
                data={item}
              />
            )
          }
        </div>
      </div>
      {error && <div className="error">{error}</div>}
    </Container>
  )
}
