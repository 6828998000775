import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Container } from '@components'

import './styles.scss'

export const RedeemFailed: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <div className="redeem-failed">
        <img src={require('@assets/icons/ic_failed.png')} alt="success" />
        <div className="failed-label">
          Failed
        </div>
        <button
          className="button"
          onClick={() => navigate('/home', { replace: true })}
        >
          Browse Voucher
        </button>
      </div>
    </Container>
  )
}
