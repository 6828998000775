import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { api, classNames, setToken } from '@utils'
import { Product, ProductCategory, ProductMerchant, ProductParams } from '@types'
import { Container, Merchant, Topbar } from '@components'

import './styles.scss'

export const CategoryProduct: React.FC = () => {
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const goBack = location?.state?.goBack

  const [title, setTitle] = useState<string>('')
  const [category, setCategory] = useState<ProductCategory[]>([])
  const [merchant, setMerhant] = useState<ProductMerchant[]>([])
  const [product, setProduct] = useState<Product[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const category_id = params.category_id

  useEffect(() => {
    const storeToken = localStorage.getItem('token')
    if (storeToken) setToken(storeToken)
  }, [])

  useEffect(() => {
    if (category_id) {
      getProductCategory(Number(category_id))
      getProductMerchant()
      getProduct({
        page: 1,
        limit: 100,
        product_category_id: Number(category_id)
      })
    }
    // eslint-disable-next-line
  }, [category_id])

  const getProductCategory = async (id: number) => {
    try {
      const limit = 100
      const page = 1
      const res = await api.get(`/api/member/v1/product-categories?limit=${limit}&page=${page}`)
      const { status_code, data } = res.data
      if (status_code === 200) {
        setCategory(data)
        setTitle(data?.find((item: ProductCategory) => item.id === id)?.name || '')
      }
    } catch (e: any) {
      setError(e?.response?.data?.message?.[0] || e?.response?.data?.message || e?.message || 'Network Error')
      if (e?.response?.data?.status_code === 401) navigate('/401')
    }
  }

  const getProductMerchant = async () => {
    try {
      const limit = 100
      const page = 1
      const res = await api.get(`/api/member/v1/product-merchants?limit=${limit}&page=${page}`)
      const { status_code, data } = res.data
      if (status_code === 200) setMerhant(data)
    } catch (e: any) {
      setError(e?.response?.data?.message?.[0] || e?.response?.data?.message || e?.message || 'Network Error')
    }
  }

  const getProduct = async (params: ProductParams) => {
    try {
      setLoading(true)
      const { page, limit, product_category_id } = params
      const res = await api.get(`/api/member/v1/products?limit=${limit}&page=${page}&product_category_id=${product_category_id}`)
      const { status_code, data } = res.data
      if (status_code === 200) setProduct(data)
      setLoading(false)
    } catch (e: any) {
      setError(e?.response?.data?.message?.[0] || e?.response?.data?.message || e?.message || 'Network Error')
    }
  }

  console.log(category, product, loading)

  return (
    <Container>
      <Topbar title={title} onBack={() => navigate('/home')} goBack={goBack} />
      <div className="voucher-tags">
        <div className="tags">
          {category.map((item, index) =>
            <div
              key={index}
              className={classNames(
                'tag',
                item.id === Number(category_id) ? 'active' : ''
              )}
              onClick={() => {
                setMerhant([])
                navigate(`/category/${item.id}`)
              }}
            >
              {item.name}
            </div>
          )}
        </div>
      </div>
      <div className="product">
        <div className="content">
          {merchant.length === 0 ?
            <>
              <Merchant />
              <Merchant />
            </>
            :
            merchant.filter(item => item.product_category_id === Number(params.category_id)).map((item: ProductMerchant, index: number) =>
              <Merchant
                key={index}
                data={item}
              />
            )
          }

        </div>
      </div>
      {error && <div className="error">{error}</div>}
    </Container>
  )
}
