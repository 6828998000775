import React, { useEffect, useRef, /* useRef,*/ useState } from 'react'
// import Slider from 'react-slick'

import { api, classNames, setToken } from '@utils'
import { useNavigate } from 'react-router-dom'

import { Product, ProductCategory, ProductMerchant, ProductParams, Profile } from '@types'
import { Container, ProfileCard, CategoryCard, /* Voucher,*/ Merchant } from '@components'

import './styles.scss'

export const Home: React.FC = () => {
  const navigate = useNavigate()

  // const sliderRef = useRef<any>()

  // const settings = {
  //   dots: false,
  //   arrows: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1
  // }

  const [profile, setProfile] = useState<Profile>()
  const [category, setCategory] = useState<ProductCategory[]>([])
  const [merchant, setMerhant] = useState<ProductMerchant[]>([])
  const [product, setProduct] = useState<Product[]>([])
  const [error, setError] = useState<string>('')

  const prevScrollY = useRef(0)

  const [goingUp, setGoingUp] = useState<boolean>(false)
  const [header, setHeader] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (prevScrollY.current < currentScrollY && goingUp) setGoingUp(false)
      if (prevScrollY.current > currentScrollY && !goingUp) setGoingUp(true)

      prevScrollY.current = currentScrollY
      setHeader(currentScrollY > 295)

      console.log(goingUp, currentScrollY)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, [goingUp])

  useEffect(() => {
    const storeToken = localStorage.getItem('token')
    if (storeToken) setToken(storeToken)
  }, [])

  useEffect(() => {
    getProfile()
    getProductCategory()
    getProductMerchant()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (category.length > 0 && merchant.length > 0) {
      getProduct({
        page: 1,
        limit: 20,
        product_category_id: category[0].id,
        merchant_id: merchant[0].merchant_id
      })
    }
  }, [category, merchant])

  const getProfile = async () => {
    try {
      const res = await api.get('/api/member/v1/addon/sufi/profile')
      const { status_code, data } = res.data
      if (status_code === 200) {
        setProfile(data)
      }
    } catch (e: any) {
      setError(e?.response?.data?.message?.[0] || e?.response?.data?.message || e?.message || 'Network Error')
      if (e?.response?.data?.status_code === 401) navigate('/401')
    }
  }

  const getProductCategory = async () => {
    try {
      const limit = 100
      const page = 1
      const res = await api.get(`/api/member/v1/product-categories?limit=${limit}&page=${page}`)
      const { status_code, data } = res.data
      if (status_code === 200) setCategory(data)
    } catch (e: any) {
      setError(e?.message || 'Network Error')
    }
  }

  const getProductMerchant = async () => {
    try {
      const limit = 100
      const page = 1
      const res = await api.get(`/api/member/v1/product-merchants?limit=${limit}&page=${page}`)
      const { status_code, data } = res.data
      if (status_code === 200) setMerhant(data)
    } catch (e: any) {
      setError(e?.message || 'Network Error')
    }
  }

  const getProduct = async (params: ProductParams) => {
    try {
      const { page, limit, merchant_id, product_category_id } = params
      const res = await api.get(`/api/member/v1/products?limit=${limit}&page=${page}&product_category_id=${product_category_id}&merchant_id=${merchant_id}`)
      const { status_code, data } = res.data
      if (status_code === 200) setProduct(data)
    } catch (e: any) {
      setError(e?.message || 'Network Error')
    }
  }

  console.log(product)

  return (
    <Container>
      <div
        className={classNames(
          'header-tags',
          header ? 'show' : 'hide'
        )}
      >
        <div className="tags">
          {category.map((item, index) =>
            <div
              key={index}
              className="tag"
              onClick={() => navigate(`/category/${item.id}`)}
            >
              {item.name}
            </div>
          )}
        </div>
      </div>
      <div className="header">
        <ProfileCard data={profile} />
        <div className="flex">
          <div className="top">
            <div
              className="title"
              style={{ color: '#fff', marginTop: 20 }}
            >
              What voucher do you like?
            </div>
            {category.length > 8 &&
              <div
                className="see-all"
                onClick={() => navigate('/categories')}
              >
                See All
              </div>
            }
          </div>
          <div className="content" style={{ justifyContent: 'flex-start' }}>
            {category.length === 0 ?
              <CategoryCard />
              :
              category.slice(0, 8).map((item: ProductCategory, index: number) =>
                <CategoryCard
                  key={index}
                  data={item}
                />
              )
            }
          </div>
        </div>
      </div>
      <div style={{ padding: 20, background: '#fff' }}>
        {/* {product.length > 0 &&
        <div className="new-voucher">
          <div className="background" />
          <div className="slider">
            <Slider {...settings} ref={sliderRef}>
              <div />
              {product.map((item: Product, index: number) =>
                <div style={{ margin: 5 }}>
                  <Voucher
                    key={index}
                    data={item}
                  />
                </div>
              )}
            </Slider>
          </div>
        </div>
      } */}
        <div className="merchant-container">
          <div className="flex">
            <div className="top">
              <div className="title" style={{ marginBottom: 20 }}>
                Explore All
              </div>
            </div>
            <div className="content-product">
              {merchant.length === 0 ?
                <>
                  <Merchant />
                  <Merchant />
                </>
                :
                merchant.map((item: ProductMerchant, index: number) =>
                  <Merchant
                    key={index}
                    data={item}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>
      {error && <div className="error">{error}</div>}
    </Container>
  )
}
