import axios from 'axios'

export const CLIENT_CODE = process.env.REACT_APP_CLIENT_CODE

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
})

export const setToken = (token: string): void => {
  api.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export function separator(value: number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}
