import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ProductMerchant } from '@types'
import { classNames, separator } from '@utils'

import './styles.scss'

interface Props {
  data?: ProductMerchant
}

export const Merchant: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate()
  return (
    <div
      className={classNames(
        'merchant',
        !data ? 'skeleton' : ''
      )}
      onClick={() => navigate(`/product/${data?.merchant_id}`)}
    >
      <div
        className="image"
        style={{ backgroundImage: `url(${data?.merchant.image})` }}
      />
      <div className="detail">
        <div className="name">{data?.merchant.name}&nbsp;</div>
        <div className="start-from">Start from:</div>
        <div className="nominal">Rp {separator(Number(data?.name?.replace(/[^0-9]/g, '')))}</div>
        <div className="point">{separator(Number(data?.selling_price))} Points</div>
      </div>
    </div>
  )
}
