import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom'

import { App } from '@app'

import reportWebVitals from './reportWebVitals'
import './index.scss'

import {
  Home, MerchantProduct, DetailProduct,
  Categories, CategoryProduct,
  RedeemSuccess, RedeemFailed,
  NotFound, Unauthorized
} from '@pages'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/home" element={<Home />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/category">
          <Route path=":category_id" element={<CategoryProduct />} />
        </Route>
        <Route path="/product">
          <Route path="detail" element={<DetailProduct />} />
          <Route path=":merchant_id" element={<MerchantProduct />} />
        </Route>
        <Route path="/redeem-success" element={<RedeemSuccess />} />
        <Route path="/redeem-failed" element={<RedeemFailed />} />
        <Route path="/401" element={<Unauthorized />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)

reportWebVitals()
