import { Container } from '@components'
import React from 'react'

import './styles.scss'

export const NotFound: React.FC = () => {
  return (
    <Container>
      <div className="not-found">
        <div className="error-code">404</div>
        <div className="label">Not Found</div>
      </div>
    </Container>
  )
}
