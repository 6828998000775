import { Profile } from '@types'
import { classNames, separator } from '@utils'
import React from 'react'

import './styles.scss'

interface Props {
  data?: Profile
}

export const ProfileCard: React.FC<Props> = ({ data }) => {
  return (
    <div
      className={classNames(
        'profile',
        !data ? 'skeleton' : ''
      )}
    >
      <div className="title">Your redeem point:</div>
      <div className="name">{data?.name}&nbsp;</div>
      <div className="point">
        <div className="value">{separator(Number(data?.point_redeem))}&nbsp;</div>
        <div className="label">Points</div>
      </div>
      <div className="image" />
    </div>
  )
}
