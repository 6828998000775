export const profileDummy = {
  "email": "agie03annan@gmail.com",
  "point_redeem": 27000,
  "name": "agie annan"
}

export const dummyCategory = [
  {
    "id": 1,
    "code": "voucher",
    "name": "voucher",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 2,
    "code": "malaysia",
    "name": "malaysia",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 3,
    "code": "game",
    "name": "game",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 4,
    "code": "data",
    "name": "data",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 5,
    "code": "indonesia",
    "name": "indonesia",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 6,
    "code": "china",
    "name": "china",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 7,
    "code": "etoll",
    "name": "etoll",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 8,
    "code": "pulsa",
    "name": "pulsa",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 9,
    "code": "pln",
    "name": "pln",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 10,
    "code": "korea",
    "name": "korea",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 11,
    "code": "singapore",
    "name": "singapore",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 12,
    "code": "taiwan",
    "name": "taiwan",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 13,
    "code": "philipines",
    "name": "philipines",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 14,
    "code": "thailand",
    "name": "thailand",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 15,
    "code": "vietnam",
    "name": "vietnam",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 16,
    "code": "bangladesh",
    "name": "bangladesh",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 17,
    "code": "nepal",
    "name": "nepal",
    "image": "https://dummyimage.com/600x400/000/fff"
  },
  {
    "id": 18,
    "code": "srilanka",
    "name": "srilanka",
    "image": "https://dummyimage.com/600x400/000/fff"
  }
]

export const dummyMerchant = [
  {
    "id": 49,
    "product_category_id": 1,
    "product_category": {
      "id": 1,
      "code": "voucher",
      "name": "voucher",
      "image": "https://dummyimage.com/600x400/000/fff"
    },
    "product_type_id": 1,
    "product_type": {
      "id": 1,
      "code": "1",
      "name": "voucher",
      "icon_url": "",
      "order_flow": "voucher"
    },
    "merchant_id": 8,
    "merchant": {
      "id": 8,
      "code": "Alfamart Voucher",
      "name": "Alfamart Voucher",
      "image": "https://cdn.mobilepulsa.net/img/product/operator_list/140119034649-Alfa-01.png",
      "delivery_method": "Text",
      "description": "-"
    },
    "code": "MPALFAMART100",
    "name": "Voucher Alfamart Rp 100.000",
    "image": "https://cdn.mobilepulsa.net/img/product/operator_list/140119034649-Alfa-01.png",
    "nominal": 99750,
    "selling_price": 25000,
    "required_point": 250,
    "active": 1
  }
]

export const dummyProduct = [
  {
    "id": 49,
    "product_category_id": 1,
    "product_category": {
      "id": 1,
      "code": "voucher",
      "name": "voucher",
      "image": "https://dummyimage.com/600x400/000/fff"
    },
    "product_type_id": 1,
    "product_type": {
      "id": 1,
      "code": "1",
      "name": "voucher",
      "icon_url": "",
      "order_flow": "voucher"
    },
    "merchant_id": 8,
    "merchant": {
      "id": 8,
      "code": "Alfamart Voucher",
      "name": "Alfamart Voucher",
      "image": "https://cdn.mobilepulsa.net/img/product/operator_list/140119034649-Alfa-01.png",
      "delivery_method": "Text",
      "description": "-"
    },
    "code": "MPALFAMART100",
    "name": "Voucher Alfamart Rp 100.000",
    "image": "https://cdn.mobilepulsa.net/img/product/operator_list/140119034649-Alfa-01.png",
    "nominal": 99750,
    "selling_price": 100000,
    "required_point": 1000,
    "active": 1
  },
  {
    "id": 50,
    "product_category_id": 1,
    "product_category": {
      "id": 1,
      "code": "voucher",
      "name": "voucher",
      "image": "https://dummyimage.com/600x400/000/fff"
    },
    "product_type_id": 1,
    "product_type": {
      "id": 1,
      "code": "1",
      "name": "voucher",
      "icon_url": "",
      "order_flow": "voucher"
    },
    "merchant_id": 8,
    "merchant": {
      "id": 8,
      "code": "Alfamart Voucher",
      "name": "Alfamart Voucher",
      "image": "https://cdn.mobilepulsa.net/img/product/operator_list/140119034649-Alfa-01.png",
      "delivery_method": "Text",
      "description": "-"
    },
    "code": "MPALFAMART25",
    "name": "Voucher Alfamart Rp 25.000",
    "image": "https://cdn.mobilepulsa.net/img/product/operator_list/140119034649-Alfa-01.png",
    "nominal": 24938,
    "selling_price": 25000,
    "required_point": 250,
    "active": 1
  }
]