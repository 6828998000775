import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Container } from '@components'

import './styles.scss'

export const RedeemSuccess: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const sendEmail = location?.state?.sendEmail
  return (
    <Container>
      <div className="redeem-success">
        <img src={require('@assets/icons/ic_success.png')} alt="success" />
        <div className="success-label">
          {sendEmail ? 'Please check your email' : 'Thank you'}
        </div>
        <button
          className="button"
          onClick={() => navigate('/home', { replace: true })}
        >
          Browse Voucher
        </button>
      </div>
    </Container>
  )
}
