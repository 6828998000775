import React, { useEffect, useState } from 'react'

import { api } from '@utils'
import { ProductCategory } from '@types'
import { dummyCategory } from '@data'
import { Container, Topbar, CategoryCard } from '@components'

import './styles.scss'

export const Categories: React.FC = () => {
  const [category, setCategory] = useState<ProductCategory[]>([])

  useEffect(() => {
    getProductCategory()
  }, [])

  const getProductCategory = async () => {
    try {
      const limit = 100
      const page = 1
      const res = await api.get(`/api/member/v1/product-categories?limit=${limit}&page=${page}`)
      const { status_code, data } = res.data
      if (status_code === 200) setCategory(data)
    } catch {
      setCategory(dummyCategory)
    }
  }

  return (
    <Container>
      <Topbar title="Categories" goBack />
      <div className="categories">
        {category.map((item: ProductCategory, index: number) =>
          <CategoryCard
            key={index}
            data={item}
          />
        )}
      </div>
    </Container>
  )
}
